import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import useMarvelService from '../../services/MarvelService';
import Spinner from '../spinner/Spinner';
import ErrorMessage from '../errorMessage/ErrorMessage';
import './charList.scss';

const CharList = (props) => {

  const [charList, setCharList] = useState([]);
  const [newItemLoading, setNewItemLoading] = useState(false);
  const [offset, setOffset] = useState(210);
  const [charEnded, setCharEnded] = useState(false);

  const {loading, error, getAllCharacters} = useMarvelService();

  // hook замість componentDidMount()
  useEffect(() => {
    onRequest(offset, true);
  }, [])

  const onRequest = (offset, initial) => {
    initial ? setNewItemLoading(false) : setNewItemLoading(true);
    getAllCharacters(offset)
        .then(onCharListLoaded)
  }
  
  // we get newCharList from .then(onCharListLoaded)
  const onCharListLoaded = (newCharList) => {
    let ended = false;
      // we determine if the characters are finished
      if (newCharList.length < 9) {
        ended = true;
      }
    // відштовхуємося всюди від попереднього стану
      setCharList(charList => [...charList, ...newCharList]);
      setNewItemLoading(newItemLoading => false);
      setOffset(offset => offset + 9);
      setCharEnded(charEnded => ended);
  }

  // використовуємо посилання на елементи
  const itemRefs = useRef([]);

  const focusOnItem = (id) => {
      // по можливості не зловживайте рефами
    if (itemRefs.current[id]) {  
      itemRefs.current.forEach(item => item.classList.remove('char__item_selected'));
      itemRefs.current[id].classList.add('char__item_selected');
      itemRefs.current[id].focus();
    }
  }

  // this method was created to optimize render
  function renderItems(arr) {
    const items =  arr.map( (item, i) => {
        let imgStyle = {'objectFit' : 'cover'};
        if (item.thumbnail === 'http://i.annihil.us/u/prod/marvel/i/mg/b/40/image_not_available.jpg') {
            imgStyle = {'objectFit' : 'unset'};
        } 

      return (
       <CSSTransition key={item.id} timeout={500} classNames="char__item"> 
        <li className="char__item" tabIndex={0}
          ref={el => itemRefs.current[i] = el}
          onClick={() => {
            props.onCharSelected(item.id);
            focusOnItem(i);
        }}
        onKeyPress={(e) => {
          if (e.key === ' ' || e.key === "Enter") {
              props.onCharSelected(item.id);
              focusOnItem(i);
          }
        }}>
            <img src={item.thumbnail} alt={item.name} style={imgStyle}/>
            <div className="char__name">{item.name}</div>
        </li>
       </CSSTransition>
      )
    } );
    // this design is made to center spinner / error
    return (
        <ul className="char__grid">
          <TransitionGroup component={null}>
              {items}
          </TransitionGroup>
        </ul>
    )
  }

  const items = renderItems(charList);

  const errorMessage = error ? <ErrorMessage/> : null;
  const spinner = loading && !newItemLoading ? <Spinner/> : null;

  return (
      <div className="char__list">
        {errorMessage}
        {spinner}
        {items}       
        <button className="button button__main button__long"
            disabled={newItemLoading}
            style={{'display': charEnded ? 'none' : 'block'}}
            onClick={() => onRequest(offset)}>
            <div className="inner">load more</div>
        </button>
      </div>
    )
}

CharList.propTypes = {
  onCharSelected: PropTypes.func.isRequired
}

export default CharList;